import React from 'react';
import { SecondaryLinkCard } from '../SecondaryLinkCard/index';
import { GroupContainer } from './styles';
import { CardsDataProps, CardsContentArr } from '../../types/shared';

export const SecondaryLinksContent: React.FC<CardsDataProps> = ({ cardsData }) => {
  let cardsArray: CardsContentArr = [...cardsData.cards];

  if (cardsArray.length > 9) {
    const slicedArray = cardsArray.slice(0, 9);
    cardsArray = slicedArray;
  }

  return (
    <GroupContainer>
      {cardsArray.map((card) => (
        <SecondaryLinkCard
          key={Math.random()}
          cardData={{
            showIcon: cardsData.displayIcons,
            openLinkNewTab: card.openLinkNewTab,
            URL: card.URL,
            cardTitle: card.cardTitle,
            iconName: card.iconName,
          }}
        />
      ))}
    </GroupContainer>
  );
};
