import { ContentChangeListener, ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import React, { ReactElement, useState } from 'react';
import type { Logger } from '@feature-hub/logger';
import { CardsData } from '../types/shared';
import { ContentContext } from './ContentContext';
import { EditorJsonContent, FalconContent } from './ContentInterfaces';

const mapHeadlessContent = (content: FalconContent): CardsData => {
  const { displayIcons, cards } = content.fields;

  const appContent: CardsData = {
    displayIcons,
    cards: cards.map((card) => ({ ...card.fields })),
  };
  return appContent;
};

const mapFaceContent = (content: EditorJsonContent): CardsData => {
  const appContent: CardsData = { ...content };
  return appContent;
};

export const ContentContextProvider: React.FC<{
  contentService: ContentServiceV1;
  loggerService?: Logger;
  children?: ReactElement;
}> = ({ contentService, children, loggerService }) => {
  const [rawContent, setRawContent] = useState(contentService.getContent());
  contentService.onContentChange(setRawContent as ContentChangeListener);
  let content: CardsData;

  if (rawContent.__type === 'aem-headless') {
    const falconContent = rawContent as FalconContent;
    content = mapHeadlessContent(falconContent);
    loggerService?.info('aem-headless-content:', falconContent);
  } else {
    const editorJsonContent = rawContent as EditorJsonContent;
    content = mapFaceContent(editorJsonContent);
  }

  /* eslint-disable react/jsx-no-constructed-context-values */
  return <ContentContext.Provider value={{ content }}>{children}</ContentContext.Provider>;
};

ContentContextProvider.displayName = 'ContentContextProvider';
