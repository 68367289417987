import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { ListItem, LinkCard, IconContainer, LinkIconContainer } from './styles';
import { ExternalLink, Forward } from '../Icon';
import { CardIcon } from './CardIcon';
import { CardContentProps } from '../../types/shared';

export const SecondaryLinkCard: React.FC<CardContentProps> = ({ cardData }) => {
  const { showIcon, openLinkNewTab, URL, cardTitle, iconName } = cardData;

  let title = cardTitle;
  if (title.length > 44) {
    title = title.slice(0, 44);
  }

  return (
    <ListItem>
      <LinkCard
        data-testid="secondary-link-card"
        showIcon={showIcon}
        href={URL}
        target={openLinkNewTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        <IconContainer showIcon={showIcon} data-testid="icon-container">
          <CardIcon stroke="#000" iconName={iconName} testid="card-icon" />
        </IconContainer>
        <Text variant="copy1">{title}</Text>
        <LinkIconContainer>
          {openLinkNewTab ? (
            <ExternalLink stroke="#666" data-testid="link-icon" />
          ) : (
            <Forward stroke="#666" data-testid="link-icon" />
          )}
        </LinkIconContainer>
      </LinkCard>
    </ListItem>
  );
};
