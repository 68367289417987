/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import App from './FeatureApp';
import { ContentContextProvider } from './context';

export interface FeatureDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '*',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    const contentService = featureServices['audi-content-service'];
    loggerService?.info('Feature App created.');

    const configProvider = featureServices['gfa:service-config-provider'];

    return {
      render: () => {
        return (
          <ContentContextProvider
            contentService={contentService as ContentServiceV1}
            loggerService={loggerService}
          >
            <App configProvider={configProvider} />
          </ContentContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
